#root {
  height: 100vh;
}

input,
textarea,
select,
.ql-editor {
  color: #333 !important;
}

.login-container {
  height: 123%;
}

.bg-gradient-login {
  background: linear-gradient(87deg, #172b4d 0, #12223d 100%) !important;
}

.btn-neutral {
  color: #4b77be !important;
}

.bg-info-blue {
  color: #4b77be !important;
}

.btn-blue {
  color: white !important ;
  background-color: #5f26c9 !important;
}

.bg-gray-side {
  background-color: #bdc3c7 !important;
}

.bg-blue-side {
  background-color: #6082b6 !important;
}

.btn-green {
  color: white !important ;
  background-color: #26c94f !important;
}

.btn-primary {
  background-color: #4b77be !important;
  border-color: #4b77be !important;
}

.btn-dark {
  background-color: #3c4043 !important;
  border-color: #3c4043 !important;
}

.page-item.active .page-link {
  background-color: #4b77be !important;
  border-color: #4b77be !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #4b77be !important;
  color: white !important;
}

.select2-selection__choice__remove {
  color: white !important;
}

.referral-container {
  width: 77vw;
  height: 80vh;
}

.referral-mobile-container {
  width: 100vw;
  height: 100vh;
}

svg.custom .node circle {
  fill: #f3f3ff;
  stroke: #d60c28;
  stroke-width: 1.5px;
}

svg.custom .node text {
  font-size: 14px;
  background-color: #444;
  fill: #f4f4f4;
  text-shadow: 0 1px 4px black;
  font-weight: 800;
}

svg.custom .node {
  cursor: pointer;
}

svg.custom path.link {
  fill: none;
  stroke: #d60c28;
  stroke-width: 1.5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4b77be;
}

.nav-pills .nav-link {
  color: #4b77be;
  font-weight: 600;
}

.tabnav > .nav-item > a {
  color: #4b77be;
}

.nav-pills .nav-link:hover {
  color: #4b77be;
}

.nav-pills .nav-link.active:hover {
  color: #ffffff;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  /* background-color: #4b77be !important; */
  color: #000000 !important;
  font-weight: 600;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item.active > .nav-link i,
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active i {
  color: #4b77be !important;
}

.navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
  color: #ffffff !important;
}

.navbar-vertical .navbar-nav .nav-link.active[data-toggle="collapse"][aria-expanded="true"]:after {
  color: #000000 !important;
}

.nav-link {
  transition: 0.3s ease;
  color: #fff !important;
  opacity: 1;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item.active > .nav-link {
  background-color: #fff !important;
  color: #000000 !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.375rem;
}

.nav-link i.ni {
  top: 0;
}

.navbar-light .navbar-nav .active > .nav-link {
  font-weight: 600;
}

.select2-selection__rendered {
  color: #333 !important;
}

.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #4b77be;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #4b77be;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
  color: #4b77be;
}

.details-header {
  padding: 0.8rem 1.25rem;
  background-color: #4b77be;
}

.details-header h3 {
  color: #fff !important;
}

.wrap-text {
  white-space: pre-wrap;
}

.react-daterange-picker__wrapper {
  border-radius: 4px;
  border-color: hsl(0, 0%, 80%) !important;
  height: 38px;
  padding-left: 8px;
  padding-right: 8px;
}

.react-daterange-picker {
  width: 100%;
}

.react-daterange-picker__inputGroup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.react-json-view {
  word-break: break-all;
}

.td-bold {
  font-weight: bold !important;
}

.summary-table tr th,
.summary-table tr td {
  vertical-align: middle;
}
